import * as React from "react"

import Layout from '../components/layout'
import Product from '../components/item'

// markup
const Documentaries = () => {
  return (
    <Layout>
      Documentaries
    </Layout>
  )
}

export default Documentaries
